import { useEffect, useState } from 'react';
import { ImagesService } from 'service/images/imagesService';
import { Card } from '../Cards';
import { Curve } from '../Curve';
import { Grid, GridItem, GridRow } from '../Grid';
import { LiquidButton } from '../LiquidButton';
import NextImage from '../NextImage';
import { SliderWrapper } from '../SliderWrapper';
import { Text as Paragraph } from '../Typography';
import {
  BannerContainer,
  ButtonWrapper,
  CarouselCard,
  Container,
  ContainerStyle,
  DesktopBannerContainer,
  InfoWrapper,
  LogoStyle,
  MaskContainerBottom,
  MaskContainerTop,
  MobileBannerContainer,
  SubbrandTitle,
} from './styles';

import { NextLink } from 'components/NextLink';

import { COLORS } from 'styles/constants';
import type { ICardItem as ICard } from '../Cards/types';
import { Slider } from '../SliderVanilla';

export interface ITitles {
  heading: string;
  subHeading: string;
}

export interface ISubbrand {
  titles?: ITitles;
  keyImage?: Record<string, string>;
  keyImageMobile?: Record<string, string>;
  logo?: string;
  cardData: ICardItem[];
  curveColor?: string;
  buttonLink?: {
    displayName: string;
    url: string;
  };
  trackingEvents?: {
    subcategoryTrackingEvent?: () => void;
    productTrackingEvent?: (data?: any, position?: number) => void;
  };
}

export interface ICardItem extends ICard {
  link?: {
    url: string;
    displayName: string;
    openInNewTab: boolean;
  };
}

export const SubbrandProducts = ({
  titles,
  keyImage,
  keyImageMobile,
  cardData,
  buttonLink,
  logo,
  curveColor = COLORS.primary,
  trackingEvents,
}: ISubbrand) => {
  const id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-subbrand-product`;
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(!!(window.innerWidth <= 640));
  }, []);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);

  const onClickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    trackingEvents?.subcategoryTrackingEvent && trackingEvents.subcategoryTrackingEvent();
  };
  return (
    <>
      <MaskContainerTop data-test={`${componentName}-mask-container-top`} $curveColor={curveColor}>
        <MaskContainerBottom data-test={`${componentName}-mask-container-bottom`}>
          <BannerContainer data-test={`${componentName}-banner-container`}>
            {isMobile ? (
              <MobileBannerContainer data-test={`${componentName}-mobile-banner-container`}>
                <NextImage sources={ImagesService.getPictureSources(keyImageMobile)} alt="" />
              </MobileBannerContainer>
            ) : (
              <DesktopBannerContainer data-test={`${componentName}-desktop-banner-container`}>
                <NextImage sources={ImagesService.getPictureSources(keyImage)} alt="" />
              </DesktopBannerContainer>
            )}
            {logo && (
              <NextImage
                src={logo}
                alt={`${componentName}-logo`}
                width={310}
                height={140}
                mimeType="image/png"
                className={LogoStyle}
                dataTestId={`${componentName}-logo`}
              />
            )}
          </BannerContainer>
        </MaskContainerBottom>
      </MaskContainerTop>
      <Container className={ContainerStyle}>
        <Grid customDesktopPadding>
          <GridRow columns={16} tabletBreakpoint>
            <GridItem colSpan={screenWidth < 1024 ? 12 : 4}>
              <InfoWrapper
                className="flex flex-col justify-center h-full items-center"
                data-test={`${componentName}-info-wrapper`}
              >
                <SubbrandTitle tag="p" type="sm" data-test={`${componentName}-subbrand-title`}>
                  {titles?.heading}
                </SubbrandTitle>
                <Paragraph tag="p" type="md" data-test={`${componentName}-text`}>
                  {titles?.subHeading}
                </Paragraph>
                <ButtonWrapper data-test={`${componentName}-button-wrapper`}>
                  <NextLink href={`${buttonLink?.url}`} target="_self" onClick={onClickHandler}>
                    <LiquidButton
                      data-test={`action-button-${cardData[0]?.id}`}
                      text={buttonLink?.displayName || 'Product'}
                      height={50}
                      width={200}
                      color={COLORS.primary}
                      textColor={COLORS.white}
                    />
                  </NextLink>
                </ButtonWrapper>
              </InfoWrapper>
            </GridItem>
            <GridItem colSpan={12}>
              <SliderWrapper data-test={`${componentName}-slider-wrapper`} className="pb-4 md:pb-0">
                <Slider isFullWidth>
                  {cardData?.map((item: ICardItem, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <CarouselCard key={item.link?.url ?? `subbrand-card-${index}`}>
                      <Card
                        data={item}
                        link={item.link}
                        cardType="small"
                        height="360px"
                        cardVariation="product"
                        trackingEvent={
                          trackingEvents?.productTrackingEvent &&
                          trackingEvents.productTrackingEvent
                        }
                        position={index + 1}
                      />
                    </CarouselCard>
                  ))}
                </Slider>
              </SliderWrapper>
            </GridItem>
          </GridRow>
        </Grid>
        <Curve
          type="top"
          className="hidden md:block top-auto bottom-[-100px] h-[200px] bg-bg-light-gray w-full"
        />
      </Container>
    </>
  );
};
